import React, { useEffect, useState } from 'react';
import './App.css';
import {SCORM} from 'pipwerks-scorm-api-wrapper';

function App() {

	const [saveData, setSaveData] = useState<string>('not set');

	useEffect(() => {
		SCORM.init();
		const suspendData = SCORM.get('cmi.suspend_data');
		setSaveData(suspendData ? suspendData : 'no data found');

		const newSaveData = {
			levels: [true, false, true, true, false, false],
			completed: false
		};
		SCORM.set('cmi.suspend_data', JSON.stringify(newSaveData));
		SCORM.save();
	},[]);

	return (
		<div >
			<b>SCORM savedata:</b> {saveData}
		</div>
	);
}

export default App;
